import axios from 'axios'

export default {
    getInfo: () => axios.get('account/general-info'),
    setInfo: (data) => axios.put('account/general-info', data),
    getAccountDetails: () => axios.get('account/details'),
    changeLogo: (data) => axios.post('account/logo', data, {headers: {"Content-Type": "multipart/form-data",}}),
    setPassword: (data) => axios.post('users/update-password', data),
    getPoints: () => axios.get('suppliers/points'),
    getPointTickets: (type) => axios.post('suppliers/point-tickets', {type: type}),
    getPointProducts: (type) => axios.post('suppliers/point-products', {type: type}),


    //Admin
    getAccounts: (filters) => axios.post('account/list',filters),
    store: (data) => axios.post('account/store',data),
    update: (data) => axios.post('account/update',data),
    inactive: (data) => axios.post('account/inactive',data),
    active: (data) => axios.post('account/active',data),

    recoverPasswordLink: (data) => axios.post('account/recover-password-link',data),
}